import React, { useState, useRef, useEffect, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import jsPDF from "jspdf";
import { useSpeechSynthesis } from "react-speech-kit";
import html2canvas from "html2canvas";
import CustomTooltipDropdown from "./CustomTooltipDropdown";
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Input,
  Text,
  PopoverCloseButton,
  Icon,
  Divider,
  IconButton,
  PopoverHeader,
  PopoverFooter,
  Link,
  Box,
  Stack,
  Textarea,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Editable,
  EditablePreview,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Image,
  Tooltip,
} from "@chakra-ui/react";
import {
  MdFilterNone,
  MdChatBubbleOutline,
  MdOutlineSave,
  MdBorderOuter,
  MdComment,
  MdReplyAll,
  MdRestore,
  MdOutlinePanToolAlt,
  MdOutlineDuo,
  MdOutlineVideoLibrary,
  MdArrowForward,
  MdOutlinePictureAsPdf,
  MdArrowBack,
  MdContactMail,
  MdOutlineScreenRotationAlt,
  MdCamera,
  MdClear,
  MdHelp,
  MdDelete,
} from "react-icons/md";

const UseBottunStyle = () => {
  return useMemo(
    () => ({
      display: "inline-flex",
      appearance: "none",
      alignItems: "center",
      userSelect: "none",
      position: "relative",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
      outline: "transparent solid 2px",
      outlineOffset: "2px",
      lineHeight: "1.2",
      borderRadius: "md",
      fontWeight: "semibold",
      transitionProperty: "common",
      transitionDuration: "normal",
      height: "var(--chakra-sizes-10)",
      minWidth: "var(--chakra-sizes-10)",
      fontSize: "md",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "gray.200",
      color: "gray.800",
      paddingTop: "var(--chakra-space-9)",
      paddingBottom: "var(--chakra-space-9)",
      textAlign: "center",
      flexDirection: "column",
      justifyContent: "center",
    }),
    []
  );
};
const UseDisableBottunStyle = () => {
  return useMemo(
    () => ({
      display: "inline-flex",
      appearance: "none",
      alignItems: "center",
      userSelect: "none",
      position: "relative",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
      outline: "transparent solid 2px",
      outlineOffset: "2px",
      lineHeight: "1.2",
      borderRadius: "md",
      fontWeight: "semibold",
      transitionProperty: "common",
      transitionDuration: "normal",
      height: "var(--chakra-sizes-10)",
      minWidth: "var(--chakra-sizes-10)",
      fontSize: "md",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "gray.200",
      color: "gray.400",
      paddingTop: "var(--chakra-space-9)",
      paddingBottom: "var(--chakra-space-9)",
      textAlign: "center",
      flexDirection: "column",
      justifyContent: "center",
    }),
    []
  );
};

const PopoverEditor = ({ iframeRef, url }) => {
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [tooltips, setTooltips] = useState([]);
  const [currentTooltipIndex, setCurrentTooltipIndex] = useState(-1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });
  const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 });
  const [mode, setMode] = useState("popover"); // "popover"、"modal"、"stroke"モードを管理
  const containerRef = useRef(null);
  const overlayRef = useRef(null);
  const placements = ["right", "top", "left", "bottom"]; // Placement順序を定義
  const [overlayVisible, setOverlayVisible] = useState(true); // オーバーレイの表示・非表示を管理
  const [summary, setSummary] = useState(""); // 要約内容
  const [isSummarizing, setIsSummarizing] = useState(false); // 要約中かどうか
  const [isRecording, setIsRecording] = useState(false); // 　動画
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const preloadPath = `${process.env.REACT_APP_API_BASE_URL}/preload.js`;
  const {
    isOpen: drawerIsOpen,
    onOpen: drawerOnOpen,
    onClose: drawerOnClose,
  } = useDisclosure();

  const {
    isOpen: modalIsOpen,
    onOpen: modalOnOpen,
    onClose: modalOnClose,
  } = useDisclosure();

  const {
    isOpen: EndNavIsOpen,
    onOpen: EndNavOnOpen,
    onClose: EndNavOnClose,
  } = useDisclosure();

  const [tooltipStates, setTooltipStates] = useState(
    tooltips.map((tooltip) => ({
      ...tooltip,
      isVisible: true, // 最初は全て表示状態
    }))
  );

  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false); // モーダルの開閉状態
  const [filename, setFilename] = useState(""); // 入力されたファイル名
  const [isLoadModalOpen, setIsLoadModalOpen] = useState(false); // モーダルの開閉状態
  const [fileList, setFileList] = useState([]); // ファイルリスト
  const [selectedFile, setSelectedFile] = useState(""); // 選択されたファイル名

  const [drawerVisible, setDrawerVisible] = useState(true); // ドロワーの表示

  const handleSummarize = async () => {
    try {
      setIsSummarizing(true);
      console.log("WebviewのHTMLコンテンツ取得を開始します...");

      // WebviewのHTMLコンテンツを取得
      const content = await window.electronAPI.getWebviewContent();

      if (content) {
        console.log("取得したHTMLコンテンツ:", content);

        // Flaskサーバーに要約リクエストを送信
        console.log("Flaskサーバーに要約リクエストを送信...");
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/summarize`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ text: content.bodyText }),
          }
        );

        if (response.ok) {
          const data = await response.json();
          const summaryText = data.summary;
          console.log("取得した要約:", summaryText);
          // 要約をドロワーに表示
          setSummary(summaryText);
          drawerOnOpen(); // ドロワーを開く
        } else {
          console.error("要約のリクエストに失敗しました。");
        }
      } else {
        console.error("HTMLコンテンツが取得できませんでした - contentが空です");
      }
    } catch (error) {
      console.error("handleSummarize 内のエラー: ", error);
    } finally {
      setIsSummarizing(false);
    }
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.text(summary, 10, 10);
    doc.save("summary.pdf");
  };

  const buttonStyle = UseBottunStyle();
  const DisablebuttonStyle = UseDisableBottunStyle();
  // ステートの追加
  const [isResizing, setIsResizing] = useState(false);
  const [currentTooltipId, setCurrentTooltipId] = useState(null);
  const [initialMousePos, setInitialMousePos] = useState({ x: 0, y: 0 });
  const [initialSize, setInitialSize] = useState({ width: 100, height: 100 });
  const [currentModalId, setCurrentModalId] = useState(null);

  // モーダルを開く際に ID を保存
  const handleOpenModal = (id) => {
    setCurrentModalId(id);
    modalOnOpen(); // モーダルを開くトリガー
  };

  // リサイズ開始時の処理
  const handleResizeMouseDown = (e, id) => {
    e.preventDefault(); // 他のデフォルトイベントを抑制
    const tooltipToResize = tooltips.find((t) => t.id === id);

    if (tooltipToResize) {
      console.log("リサイズ開始", { id, tooltipToResize }); // リサイズ対象の位置を確認
      setIsResizing(true);
      setCurrentTooltipId(id);

      // マウスの位置を基準にリサイズの開始位置を設定
      setInitialMousePos({ x: e.clientX, y: e.clientY });

      // 矩形の初期サイズを設定
      setInitialSize({
        width: tooltipToResize.width || 100,
        height: tooltipToResize.height || 100,
      });

      // `window` にイベントリスナーを追加
      console.log("mousemove イベント追加");
      window.addEventListener("mousemove", handleResizing); // window に変更
      window.addEventListener("mouseup", handleResizeMouseUp); // window に変更
    }
  };

  // リサイズ終了時の処理
  const handleResizeMouseUp = () => {
    console.log("リサイズ終了"); // ログ追加
    setIsResizing(false);
    setCurrentTooltipId(null);

    // イベントリスナーを削除
    console.log("mousemove イベント削除");
    window.removeEventListener("mousemove", handleResizing); // window に変更
    window.removeEventListener("mouseup", handleResizeMouseUp); // window に変更
  };

  // リサイズ中の処理
  const handleResizing = (e) => {
    if (!isResizing) return;

    const deltaX = e.clientX - initialMousePos.x;
    const deltaY = e.clientY - initialMousePos.y;

    console.log("リサイズ中", { deltaX, deltaY }); // ログ追加

    const newWidth = Math.max(initialSize.width + deltaX, 10); // 最小幅を10pxに
    const newHeight = Math.max(initialSize.height + deltaY, 10); // 最小高さを10pxに

    console.log("新しいサイズ", { newWidth, newHeight }); // サイズ確認用

    setTooltips((prevTooltips) =>
      prevTooltips.map((tooltip) =>
        tooltip.id === currentTooltipId
          ? { ...tooltip, width: newWidth, height: newHeight }
          : tooltip
      )
    );
  };

  // Electron環境かどうかを確認する関数 createContextでまとめて外部で使用
  const isElectron = () => {
    return navigator.userAgent.toLowerCase().includes("electron");
  };

  useEffect(() => {
    if (url) {
      setIframeLoaded(false);
      setTooltips([]);
    }
  }, [url]);

  const saveTour = () => {
    if (!filename) {
      alert("ファイル名を入力してください");
      return;
    }

    const tooltipsToSave = tooltips.map((tooltip) => ({
      id: tooltip.id,
      top: tooltip.top,
      left: tooltip.left,
      Ttitle: tooltip.Ttitle,
      Ttext: tooltip.Ttext,
      Timage: tooltip.Timage,
      TScolor: tooltip.TScolor || "#ffffff",
      type: tooltip.type,
      placement: tooltip.placement,
      placementIndex: tooltip.placementIndex,
      number: tooltip.number,
      width: tooltip.width,
      height: tooltip.height,
      isVisible: tooltip.isVisible,
    }));

    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/tour/${filename}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ tooltips: tooltipsToSave }),
    })
      .then((response) => response.json())
      .then((tooltip) => {
        console.log("Tooltips saved!:", tooltip);
        setIsSaveModalOpen(false); // モーダルを閉じる
        setFilename(""); // ファイル名をリセット
      })
      .catch((error) => {
        console.error("Error saving tooltips:", error);
      });
  };

  const fetchFileList = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/tour-list`)
      .then((response) => response.json())
      .then((data) => setFileList(data.files || [])) // ファイルリストを状態に保存
      .catch((error) => {
        console.error("ファイルリストの取得エラー: ", error);
      });
  };

  // コンポーネント初期化時にファイルリストを取得
  useEffect(() => {
    fetchFileList();
  }, []);

  const loadTour = (filename) => {
    if (!filename) {
      console.error("ファイル名が指定されていません");
      return;
    }

    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/tour/${filename}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.tooltips) {
          console.log("読み込んだツールチップデータ: ", data.tooltips);
          setTooltips(data.tooltips); // ツールチップをそのまま置き換える
        }
      })
      .catch((error) => {
        console.error("ツアーデータの読み込みエラー: ", error);
      });
  };

  //ファイル削除用関数
  const deleteFile = (fileName) => {
    if (window.confirm(`${fileName} を削除しますか？`)) {
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/tour/${fileName.replace(
          ".json",
          ""
        )}`,
        {
          method: "DELETE",
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("ファイル削除に失敗しました");
          }
          // 成功したらローカルのファイルリストも更新
          setFileList((prevList) =>
            prevList.filter((file) => file !== fileName)
          );
        })
        .catch((error) => {
          console.error("ファイル削除エラー: ", error);
          alert("削除に失敗しました");
        });
    }
  };

  // クリック位置を取得しポップオーバーを作成する関数
  const handleOverlayClick = (e) => {
    if (!overlayVisible) return; // オーバーレイが非表示ならクリックを無視する
    const rect = overlayRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setClickPosition({ x, y });

    const newTooltip = {
      id: uuidv4(),
      top: y,
      left: x,
      Ttitle: "",
      Ttext: "",
      Timage: "",
      TScolor: "#ffffff",
      type: mode, // モードを保持
      placement: placements[0], // 初期の位置を "right" に設定
      placementIndex: 0, // ここで初期値を0に設定
      number: tooltips.length + 1,
      isVisible: true,
    };

    console.log("新しいツールチップが作成されました:", newTooltip);
    setTooltips([...tooltips, newTooltip]);
  };

  const handlePlay = () => {
    console.log("handlePlayが呼ばれました");

    // 全てのツールチップを非表示に設定
    setTooltips((prevTooltips) =>
      prevTooltips.map((tooltip) => ({
        ...tooltip,
        isVisible: false, // すべて非表示に設定
      }))
    );

    // 再生処理をトリガーするフラグをtrueに
    setIsPlaying(true);
    setCurrentTooltipIndex(0); // 最初のツールチップから再生
  };

  useEffect(() => {
    let timer;

    if (isPlaying) {
      if (currentTooltipIndex < tooltips.length) {
        // 現在のツールチップを表示
        timer = setTimeout(() => {
          setTooltips((prevTooltips) =>
            prevTooltips.map((tooltip) =>
              tooltip.number === currentTooltipIndex + 1
                ? { ...tooltip, isVisible: true } // 現在のインデックスのツールチップを表示
                : tooltip
            )
          );
          setCurrentTooltipIndex((prevIndex) => prevIndex + 1);
        }, 3000); // 3秒ごとに次のツールチップを表示
      } else if (currentTooltipIndex === tooltips.length) {
        // 全ツールチップが表示された後
        timer = setTimeout(() => {
          setIsPlaying(false); // 再生を停止
          EndNavOnOpen(); // 説明終わりモーダルを開く
        }, 5000); // 最後のツールチップが表示されてから2秒後にモーダルを表示
      }
    }

    return () => clearTimeout(timer);
  }, [isPlaying, currentTooltipIndex, tooltips.length, EndNavOnOpen]);

  // 効率学習ボタンの処理
  const handleEfficientLearning = () => {
    setCurrentTooltipIndex(-1);
    setTimeout(() => {
      setCurrentTooltipIndex(0);
    }, 5000); // 5秒後に再生を開始
  };

  // 動画撮影のオン/オフを切り替える関数
  const handleVideoToggle = async () => {
    if (!isRecording) {
      await startRecording();
    } else {
      stopRecording();
    }
  };

  // 動画撮影を開始する関数
  const startRecording = async () => {
    try {
      // 画面共有の許可を求める
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: { cursor: "always" },
        audio: false,
      });

      const recorder = new MediaRecorder(stream);
      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          setRecordedChunks((prev) => [...prev, event.data]);
        }
      };

      recorder.onstop = () => {
        const blob = new Blob(recordedChunks, { type: "video/webm" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "recording.webm";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      };

      recorder.start();
      setMediaRecorder(recorder);
      setIsRecording(true);
    } catch (err) {
      console.error("画面共有の開始に失敗しました: ", err);
    }
  };

  // 動画撮影を停止する関数
  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
    setIsRecording(false);
  };

  // スクリーンショットを撮影する関数
  const handleScreenshot = async () => {
    if (overlayRef.current) {
      // overlayRefの親要素を取得する
      const parentElement = overlayRef.current.parentNode;

      if (parentElement) {
        const canvas = await html2canvas(parentElement, { useCORS: true });
        const imgData = canvas.toDataURL("image/png");

        // スクリーンショットをダウンロードするためのリンクを作成
        const a = document.createElement("a");
        a.href = imgData;
        a.download = "screenshot.png";
        a.click();
      }
    }
  };

  // 番号変更の処理
  const handleNumberChange = (id, newNumber) => {
    const existingTooltip = tooltips.find(
      (tooltip) => tooltip.number === newNumber
    );
    setTooltips((prevTooltips) =>
      prevTooltips.map((tooltip) => {
        if (tooltip.id === id) {
          return { ...tooltip, number: newNumber };
        } else if (existingTooltip && tooltip.id === existingTooltip.id) {
          return {
            ...tooltip,
            number: tooltips.find((t) => t.id === id).number,
          };
        }
        return tooltip;
      })
    );
  };

  // オーバーレイの表示・非表示を切り替える関数
  const toggleOverlay = () => {
    setOverlayVisible(!overlayVisible);
  };

  // スクロール位置を追跡する関数
  const handleScroll = () => {
    if (containerRef.current) {
      setScrollPosition({
        x: containerRef.current.scrollLeft,
        y: containerRef.current.scrollTop,
      });
    }
  };

  // ツールチップを削除する関数
  const removeTooltip = (id) => {
    setTooltips((prevTooltips) =>
      prevTooltips.filter((tooltip) => tooltip.id !== id)
    );
  };

  // ツールチップのテキスト変更処理
  const handleTooltipChange = (id, updatedFields) => {
    setTooltips((prevTooltips) =>
      prevTooltips.map((tooltip) =>
        tooltip.id === id ? { ...tooltip, ...updatedFields } : tooltip
      )
    );
  };
  // モード切り替え用ボタン
  const toggleMode = (newMode) => {
    setMode(newMode);
  };

  // アイコンが押されるたびに placement を切り替える関数
  const togglePlacement = (id) => {
    setTooltips((prevTooltips) =>
      prevTooltips.map((tooltip) =>
        tooltip.id === id
          ? {
              ...tooltip,
              placementIndex: (tooltip.placementIndex + 1) % placements.length,
              placement:
                placements[(tooltip.placementIndex + 1) % placements.length],
            }
          : tooltip
      )
    );
  };

  // 読み上げ
  const { speak, voices } = useSpeechSynthesis();
  const [isSpeaking, setIsSpeaking] = useState(false);
  const selectedVoice = voices.find((voice) => voice.lang === "ja-JP");

  const handleSpeechToggle = () => {
    if (window.speechSynthesis.speaking) {
      window.speechSynthesis.cancel(); // Stop
      setIsSpeaking(false);
    } else {
      speak({
        text: "デスクモザイクは、フリーアドレスにおいて座席予約を最適化します。座席を一覧し、予約やチェックイン状態をすぐに見る事ができます。",
        voice: selectedVoice,
      });
      setIsSpeaking(true);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {/* ドロワー */}
      <Drawer
        isOpen={drawerIsOpen}
        placement="right"
        onClose={drawerOnClose}
        size="full"
      >
        <DrawerOverlay />
        <DrawerContent
          transition="width 0.3s ease"
          width={drawerVisible ? "400px" : "30px"}
          overflow="hidden"
        >
          <DrawerHeader
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text visibility={drawerVisible ? "visible" : "hidden"}>
              要約結果
            </Text>
            <Button
              variant="ghost"
              onClick={() => setDrawerVisible(!drawerVisible)}
            >
              {drawerVisible ? <MdArrowBack /> : <MdArrowForward />}
            </Button>
          </DrawerHeader>
          <DrawerBody display={drawerVisible ? "block" : "none"}>
            <Text whiteSpace="pre-wrap">{summary}</Text>
          </DrawerBody>
          <DrawerFooter display={drawerVisible ? "flex" : "none"}>
            <Button colorScheme="blue" onClick={handleDownloadPDF}>
              ダウンロード
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <Stack direction="row" h="100px" p={4}>
        {/* ポップオーバー作成モードボタン */}

        <Button
          leftIcon={<Icon as={MdChatBubbleOutline} w={8} h={8} />}
          onClick={() => toggleMode("popover")}
          mx={1}
          sx={buttonStyle}
        >
          <Text fontSize="sm">新規ポップオーバー</Text>
        </Button>
        {/* モーダル作成モードボタン */}
        <Button
          leftIcon={<Icon as={MdFilterNone} w={8} h={8} />}
          onClick={() => toggleMode("modal")}
          mx={1}
          sx={buttonStyle}
        >
          <Text fontSize="sm">新規モーダル</Text>
        </Button>
        {/* 矩形囲み モードボタン */}
        <Button
          leftIcon={<Icon as={MdBorderOuter} w={8} h={8} />}
          onClick={() => toggleMode("pointStroke")}
          ml={1}
          mr={5}
          sx={buttonStyle}
        >
          <Text fontSize="sm">矩形で囲む</Text>
        </Button>
        <Divider orientation="vertical" />
        <Button
          ml={5}
          mr={1}
          onClick={() => setIsSaveModalOpen(true)} // 保存のためのボタン
          leftIcon={<Icon as={MdOutlineSave} w={8} h={8} />} // アイコンをボタンの左に配置
          sx={buttonStyle}
        >
          <Text fontSize="md">保存</Text>
        </Button>

        <Button
          mx={1}
          onClick={() => setIsLoadModalOpen(true)} // モーダルを開く
          leftIcon={<Icon as={MdReplyAll} w={8} h={8} />} // アイコンをボタンの左に配置
          sx={buttonStyle}
        >
          <Text fontSize="md">読込</Text>
        </Button>

        <Button
          mx={1}
          leftIcon={<Icon as={MdOutlineVideoLibrary} w={8} h={8} />} // アイコンをボタンの左に配置
          sx={buttonStyle}
          onClick={handlePlay}
        >
          <Text fontSize="md">自動再生</Text>
        </Button>
        <Button
          mx={1}
          leftIcon={<Icon as={MdOutlineVideoLibrary} w={8} h={8} />} // アイコンをボタンの左に配置
          sx={DisablebuttonStyle}
          onClick={handlePlay}
        >
          <Text fontSize="md">手動再生</Text>
        </Button>

        <Button
          ml={1}
          mr={5}
          leftIcon={<Icon as={MdOutlinePanToolAlt} w={8} h={8} />} // アイコンをボタンの左に配置
          sx={buttonStyle}
          onClick={toggleOverlay}
        >
          <Text fontSize="md">{overlayVisible ? "操作する" : "操作ON"}</Text>
        </Button>
        <Button
          mx={1}
          leftIcon={<Icon as={MdOutlineVideoLibrary} w={8} h={8} />} // アイコンをボタンの左に配置
          sx={DisablebuttonStyle}
          onClick={handlePlay}
        >
          <Text fontSize="md">説明生成</Text>
        </Button>

        <Divider orientation="vertical" />
        <Button
          ml={5}
          mr={1}
          onClick={handlePlay}
          leftIcon={<Icon as={MdComment} w={8} h={8} />} // アイコンをボタンの左に配置
          sx={DisablebuttonStyle}
        >
          <Text fontSize="md">効率学習</Text>
        </Button>
        <Button
          mx={1}
          onClick={handleEfficientLearning}
          leftIcon={<Icon as={MdRestore} w={8} h={8} />} // アイコンをボタンの左に配置
          sx={DisablebuttonStyle}
        >
          <Text fontSize="md">ログ</Text>
        </Button>

        <Button
          mx={1}
          leftIcon={<Icon as={MdOutlinePictureAsPdf} w={8} h={8} />} // アイコンをボタンの左に配置
          sx={DisablebuttonStyle}
          onClick={handleSummarize}
          disabled={isSummarizing}
        >
          <Text fontSize="md"> {isSummarizing ? "要約中..." : "PDF化"}</Text>
        </Button>

        {/* 動画撮影のボタン */}
        <Button
          mx={1}
          leftIcon={<Icon as={MdOutlineDuo} w={8} h={8} />}
          sx={buttonStyle}
          onClick={handleVideoToggle}
        >
          <Text fontSize="md">{isRecording ? "動画撮影" : "動画ON"}</Text>
        </Button>

        <Button
          mx={1}
          leftIcon={<Icon as={MdCamera} w={8} h={8} />} // アイコンをボタンの左に配置
          sx={DisablebuttonStyle}
          onClick={handleScreenshot}
        >
          <Text fontSize="md">スクショ</Text>
        </Button>

        <Button
          onClick={handleSpeechToggle}
          mx={1}
          leftIcon={<Icon as={MdOutlineDuo} w={8} h={8} />}
          sx={DisablebuttonStyle}
        >
          <Text fontSize="md">{isSpeaking ? "音声停止" : "音声再生"}</Text>
        </Button>
        <Button
          mx={1}
          onClick={handleEfficientLearning}
          leftIcon={<Icon as={MdContactMail} w={8} h={8} />} // アイコンをボタンの左に配置
          sx={DisablebuttonStyle}
        >
          <Text fontSize="md">お客様に説明チケットを発行する</Text>
        </Button>
        <Tooltip label="お客様に説明チケットを発行し、製品の操作説明としてお渡しします。FAQやフォーラム、チャットbotにもつながります。">
          <Button size="s" variant="ghost">
            <Icon as={MdHelp} />
          </Button>
        </Tooltip>

        {/* クリック位置とスクロール位置の座標を表示 */}
        {/* <div>
          clickX: {clickPosition.x},<br /> Y: {clickPosition.y}
          <br />
          scrollY: {scrollPosition.y}
        </div> */}
      </Stack>
      <div
        ref={containerRef}
        style={{
          width: "97vw",
          height: "100vh",
          position: "relative",
          overflow: "auto", // スクロールを許可
        }}
        onScroll={handleScroll} // containerのスクロールイベント
      >
        {isElectron() ? (
          <div>
            <webview
              src={url}
              style={{
                width: "100%",
                height: "500vh",
                border: "1px solid #ccc",
                position: "relative",
                zIndex: 1,
                overflow: "auto",
              }}
              autosize="on"
              id="soosaWindow"
              preload={preloadPath}
              onWheel={(e) => e.stopPropagation()}
              disablewebsecurity="true"
            ></webview>
          </div>
        ) : (
          <iframe
            ref={iframeRef}
            src={url}
            onLoad={() => setIframeLoaded(true)}
            style={{
              width: "100%",
              height: "500vh",
              border: "1px solid #ccc",
              opacity: iframeLoaded ? 1 : 0.8,
              position: "relative",
              zIndex: 1, // iframeはz-index:1に設定
              overflow: "auto",
            }}
          />
        )}
        ;{/* 生成されたツールチップを表示 */}
        {tooltips.map((tooltip, index) => (
          <div
            key={tooltip.id}
            className={`tooltipanim ${tooltip.isVisible ? "zoomin" : ""}`}
            style={{
              opacity: tooltip.isVisible ? 1 : 0,
              transition: "opacity 1s ease-in-out",
            }}
            isOpen={currentTooltipIndex === index || currentTooltipIndex === -1}
          >
            {tooltip.type === "popover" && (
              <Popover isOpen={true} placement={tooltip.placement}>
                <PopoverTrigger>
                  <div
                    id={`tooltip-${tooltip.id}`}
                    style={{
                      position: "absolute",
                      top: `${tooltip.top}px`,
                      left: `${tooltip.left}px`,
                      backgroundColor: "black",
                      color: "white",
                      borderRadius: "50%",
                      width: "0px",
                      height: "0px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  ></div>
                </PopoverTrigger>
                <PopoverContent
                  style={{
                    zIndex: 1030,
                    width: "auto",
                    minWidth: "40px",
                    maxWidth: "800px",
                  }}
                >
                  <PopoverArrow />
                  <PopoverCloseButton
                    aria-label="Remove tooltip"
                    onClick={() => removeTooltip(tooltip.id)}
                  />
                  <PopoverHeader
                    textAlign="center"
                    pt={4}
                    fontWeight="bold"
                    border="0"
                  >
                    <CustomTooltipDropdown
                      tooltip={tooltip}
                      tooltips={tooltips}
                      handleNumberChange={handleNumberChange}
                    />

                    <Input
                      variant="flushed"
                      placeholder="説明タイトル"
                      value={tooltip.Ttitle}
                      onChange={(e) =>
                        handleTooltipChange(tooltip.id, {
                          Ttitle: e.target.value,
                        })
                      }
                      sx={{
                        fontWeight: "bold",
                      }}
                    />
                  </PopoverHeader>

                  <PopoverBody>
                    <Editable
                      border="0"
                      textAlign="center"
                      fontSize="md"
                      isPreviewFocusable={false}
                    >
                      <EditablePreview value={tooltip.Ttext} />
                      <Textarea
                        value={tooltip.Ttext}
                        style={{
                          border: "none",
                          resize: "both",
                          width: "fit-content",
                          height: "fit-content",
                          minHeight: "30px",
                          maxHeight: "760px",
                          minWidth: "190px",
                          maxWidth: "760px",
                        }}
                        rows={1}
                        placeholder="説明本文"
                        onChange={(e) =>
                          handleTooltipChange(tooltip.id, {
                            Ttext: e.target.value,
                          })
                        }
                      />
                    </Editable>
                  </PopoverBody>

                  <PopoverFooter
                    border="0"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    pb={4}
                  >
                    <Box fontSize="sm">
                      {
                        <Icon
                          as={MdOutlineScreenRotationAlt}
                          w={8}
                          h={8}
                          cursor="pointer"
                          onClick={() => {
                            console.log(tooltip); // IDを確認
                            togglePlacement(tooltip.id);
                          }}
                        />
                      }
                    </Box>
                  </PopoverFooter>
                </PopoverContent>
              </Popover>
            )}

            {tooltip.type === "modal" && (
              <Box
                position="relative"
                style={{
                  position: "absolute",
                  top: `${tooltip.top}px`,
                  left: `${tooltip.left}px`,
                  zIndex: 1030,
                }}
              >
                <CustomTooltipDropdown
                  tooltip={tooltip}
                  tooltips={tooltips}
                  handleNumberChange={handleNumberChange}
                />

                {/* 閉じるボタン (×) */}
                <IconButton
                  icon={<MdClear />} // "×"
                  onClick={() => removeTooltip(tooltip.id)} // ×ボタンを押すとツールチップが削除される
                  size="sm"
                  aria-label="Close tooltip"
                  style={{
                    position: "absolute",
                    top: "-10px", // アイコンの上に配置
                    right: "-10px", // アイコンの右に配置
                    backgroundColor: "white", // 背景色を設定
                    zIndex: 1031, // ヘルプボタンより前面に表示
                    borderRadius: "50%",
                    boxShadow:
                      "0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)",
                  }}
                />
                {/* アイコンボタン (ヘルプボタン) */}
                <Button
                  m={1}
                  w={10}
                  h={10}
                  onClick={() => handleOpenModal(tooltip.id)}
                >
                  <Text fontSize="md">?</Text>
                </Button>
              </Box>
            )}

            {/* モーダル内の閉じるボタン */}
            {tooltip.type === "modal" &&
              tooltip.id === currentModalId &&
              modalIsOpen && (
                <Box>
                  <Modal isOpen={modalIsOpen} onClose={modalOnClose} isCentered>
                    <ModalContent>
                      <ModalHeader
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        {console.log(
                          `モーダルがレンダリングされました: id=${tooltip.id}`
                        )}
                      </ModalHeader>
                      <ModalCloseButton />
                      <ModalBody>
                        <Input
                          variant="flushed"
                          placeholder="説明タイトル"
                          value={tooltip.Ttitle}
                          mx={5}
                          onChange={(e) =>
                            handleTooltipChange(tooltip.id, {
                              Ttitle: e.target.value,
                            })
                          }
                          sx={{
                            fontWeight: "bold",
                          }}
                        />
                        <Input
                          type="file"
                          id="SoosaModal"
                          accept="image/*"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                              console.log("ファイルが選択されました:", file); // ファイルが取得されているか確認

                              // FileReaderを使って画像プレビューのURLを生成
                              const reader = new FileReader();
                              reader.onload = function (event) {
                                console.log(
                                  "FileReaderから取得したURL:",
                                  event.target.result
                                ); // URLが正しく取得されているか確認
                                // プレビュー画像のURLを生成してツールチップに保存（リロード時の再取得用）
                                handleTooltipChange(tooltip.id, {
                                  Tpreview: event.target.result,
                                });
                              };

                              // ファイルをDataURLとして読み込む
                              reader.readAsDataURL(file);

                              // サーバーに画像をアップロードする
                              const formData = new FormData();
                              formData.append("file", file);

                              fetch(
                                `${process.env.REACT_APP_API_BASE_URL}/api/uploads`,
                                {
                                  method: "POST",
                                  body: formData,
                                }
                              )
                                .then((response) => response.json())
                                .then((data) => {
                                  console.log(
                                    "画像がアップロードされました:",
                                    data
                                  );

                                  // サーバーから返された画像のパスをツールチップに保存する
                                  handleTooltipChange(tooltip.id, {
                                    Timage: `/api/uploads/${data.filename}`,
                                  });
                                })
                                .catch((error) => {
                                  console.error(
                                    "画像のアップロード中にエラーが発生しました:",
                                    error
                                  );
                                });
                            }
                          }}
                          mt={4}
                        />

                        {tooltip.Tpreview || tooltip.Timage ? (
                          <Image
                            id="SoosaImg"
                            src={
                              tooltip.Tpreview
                                ? tooltip.Tpreview // ローカルのプレビュー
                                : `${process.env.REACT_APP_API_BASE_URL}${tooltip.Timage}` // サーバー変数のパス.envで管理
                            }
                            alt="Selected"
                            boxSize="100%"
                            objectFit="cover"
                            my={4}
                          />
                        ) : null}
                        <Editable
                          border="0"
                          textAlign="center"
                          fontSize="md"
                          isPreviewFocusable={false}
                        >
                          <EditablePreview value={tooltip.Ttext} />
                          <Textarea
                            value={tooltip.Ttext}
                            style={{ border: "none", resize: "both" }}
                            placeholder="説明本文"
                            onChange={(e) =>
                              handleTooltipChange(tooltip.id, {
                                Ttext: e.target.value,
                              })
                            }
                          />
                        </Editable>
                      </ModalBody>
                      <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={modalOnClose}>
                          閉じる
                        </Button>
                        <Button
                          colorScheme="blue"
                          onClick={() => setIsSaveModalOpen(true)}
                        >
                          保存
                        </Button>
                      </ModalFooter>
                    </ModalContent>
                  </Modal>
                </Box>
              )}

            {/* 矩形描画モード */}
            {tooltip.type === "pointStroke" && (
              <Box
                position="absolute"
                style={{
                  top: `${tooltip.top}px`,
                  left: `${tooltip.left}px`,
                  width: `${tooltip.width || 100}px`,
                  height: `${tooltip.height || 100}px`,
                  borderRadius: "10px",
                  border: `10px solid ${tooltip.TScolor || "#ffffff"}`,
                  zIndex: 1030,
                  boxShadow:
                    "0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)",
                }}
              >
                {/* リサイズハンドル */}
                <Box
                  onMouseMove={(e) => handleResizeMouseDown(e, tooltip.id)}
                  style={{
                    position: "absolute",
                    width: "16px",
                    height: "16px",
                    bottom: "0",
                    right: "0",
                    cursor: "se-resize",
                    backgroundColor: "transparent",
                    zIndex: 1032,
                    pointerEvents: "auto",
                  }}
                />

                {/* 閉じるボタン (×ボタン) */}
                <IconButton
                  icon={<MdClear />}
                  onClick={() => removeTooltip(tooltip.id)}
                  size="sm"
                  aria-label="Close tooltip"
                  style={{
                    position: "absolute",
                    top: "-10px",
                    right: "-10px",
                    backgroundColor: "white",
                    zIndex: 1031,
                    borderRadius: "50%",
                    boxShadow:
                      "0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)",
                  }}
                />
                <CustomTooltipDropdown
                  tooltip={tooltip}
                  tooltips={tooltips}
                  handleNumberChange={handleNumberChange}
                />
                {/* 色選択ボタン */}
                <Box
                  style={{
                    position: "absolute",
                    top: "-41px",
                    left: "8px",
                    backgroundColor: "transparent",
                    padding: 3,
                  }}
                >
                  <input
                    type="color"
                    defaultValue="#ffffff"
                    value={tooltip.TScolor || "#ffffff"}
                    onChange={(e) => {
                      // tooltipの色を更新
                      handleTooltipChange(tooltip.id, {
                        TScolor: e.target.value,
                      });
                    }}
                  />
                </Box>
              </Box>
            )}
            {/* 説明終わりモーダル */}
            <Modal isOpen={EndNavIsOpen} onClose={EndNavOnClose} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>〇〇社 製品 操作説明</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Text>
                    これでご説明はおしまいです。
                    <br />
                    お付き合いいただきありがとうございました。
                    <br /> <br />
                    少しでもご不明に思われた点がございましたら <br />
                    <Link
                      href="https://example.com/forum"
                      color="teal.500"
                      isExternal
                    >
                      お客様専用Webフォーラム
                    </Link>
                    、
                    <Link
                      href="https://example.com/faq"
                      color="teal.500"
                      isExternal
                    >
                      FAQ
                    </Link>
                    、
                    <Link
                      href="https://example.com/chatbot"
                      color="teal.500"
                      isExternal
                    >
                      チャットbot
                    </Link>
                    <br />
                    にぜひお気軽にお問合せください。
                    <br /> <br />
                    弊社の製品にご満足いただけるよう、丁寧に対応させていただきます。{" "}
                    <br />
                    ご利用、お待ちしております。
                  </Text>
                </ModalBody>
                <ModalFooter>
                  <Button onClick={EndNavOnClose}>閉じる</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/* jsonファイル保存モーダル */}
            <Modal
              isOpen={isSaveModalOpen}
              onClose={() => setIsSaveModalOpen(false)}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>ファイル名を入力してください</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Input
                    placeholder="ファイル名"
                    value={filename}
                    onChange={(e) => setFilename(e.target.value)}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={saveTour}>
                    保存
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={() => setIsSaveModalOpen(false)}
                  >
                    キャンセル
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            {/* JSONファイル読み込みモーダル */}
            <Modal
              isOpen={isLoadModalOpen}
              onClose={() => setIsLoadModalOpen(false)}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>ファイルを選択してください</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {/* ファイルリストを表示 */}
                  <Stack spacing={2}>
                    {fileList.map((file) => (
                      <Stack direction="row" alignItems="center" key={file}>
                        <Button
                          flex="1"
                          variant={selectedFile === file ? "solid" : "outline"} // 選択状態を反映
                          onClick={() => setSelectedFile(file)} // 選択されたファイルを設定
                        >
                          {file}
                        </Button>
                        <IconButton
                          aria-label="Delete File"
                          icon={<MdDelete />} // ゴミ箱アイコン
                          colorScheme="red"
                          size="sm"
                          onClick={() => deleteFile(file)}
                        />
                      </Stack>
                    ))}
                  </Stack>
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={() => {
                      if (selectedFile) {
                        loadTour(selectedFile.replace(".json", "")); // ファイル名から拡張子を除去して読み込み
                        setIsLoadModalOpen(false); // モーダルを閉じる
                      } else {
                        alert("ファイルを選択してください");
                      }
                    }}
                  >
                    読込
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={() => setIsLoadModalOpen(false)}
                  >
                    キャンセル
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </div>
        ))}
        {/* iframeを覆うオーバーレイ */}
        <div
          ref={overlayRef}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "500vh",
            zIndex: 1, // iframeの上に配置
            pointerEvents: "auto", // クリックイベントをキャッチ
            backgroundColor: "#000",
            overflow: "scroll",
            opacity: 0.3,
            display: overlayVisible ? "block" : "none",
          }}
          onClick={handleOverlayClick} // クリックイベントをキャッチ
        />
      </div>
    </div>
  );
};

export default PopoverEditor;
