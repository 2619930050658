import React, { useState, useRef } from "react";
import {
  Box,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  Stack,
  Heading,
  Card,
  CardBody,
  Button,
  Flex,
  Tooltip,
  Text,
} from "@chakra-ui/react";
import { MdScatterPlot, MdHelp } from "react-icons/md";
import PopoverEditor from "./components/PopoverEditor";

function App() {
  const [saasUrl, setSaasUrl] = useState("");
  const iframeRef = useRef(null);
  // Electron環境かどうかを確認する関数 createContextで後でまとめて外部で使用
  const isElectron = () => {
    return navigator.userAgent.toLowerCase().includes("electron");
  };

  return (
    <Box bgGradient="linear(to-l, #efefef, #e9eef0)" minH="100vh" py={5}>
      <Card
        align="center"
        mx={5}
        style={{
          height: "97vh",
          overflow: "scroll",
          border: "8px solid white",
        }}
      >
        <CardBody>
          <Stack direction="row" px={5} className="App">
            <Heading as="h1" size="xl" mx={5}>
              <Icon as={MdScatterPlot} />
              SOOSA
            </Heading>
            {/*URLの入力フィールド */}
            <InputGroup size="sm" mx={5} mt={2} style={{ width: "30vw" }}>
              <InputLeftAddon> URL</InputLeftAddon>
              <Input
                placeholder="プロダクトURLを入力"
                value={saasUrl}
                onChange={(e) => setSaasUrl(e.target.value)}
                style={{ width: "30vw" }}
              />
            </InputGroup>
            {/* ボタンのラップ用 Flex コンテナ */}

            {isElectron() ? (
              <Button mt={1} ml={2}>
                デモブラウザに戻る
              </Button>
            ) : (
              <Button mt={1} ml={2}>
                SOOSA専用ブラウザを使う
              </Button>
            )}

            <Tooltip label="デモブラウザでURLを読み込めない場合、このSoosa専用ブラウザをダウンロードすればカンタンに読み込めます">
              <Button size="s" variant="ghost">
                <Icon as={MdHelp} />
              </Button>
            </Tooltip>
          </Stack>

          {/* SaaS用iframe */}

          <PopoverEditor iframeRef={iframeRef} url={saasUrl} />
        </CardBody>
      </Card>
    </Box>
  );
}

export default App;
