import React, { useState } from "react";

function CustomTooltipDropdown({ tooltip, tooltips, handleNumberChange }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleSelect = (value) => {
    handleNumberChange(tooltip.id, value);
    setIsDropdownOpen(false);
  };

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      {/* ボタン部分 */}
      <div
        onClick={toggleDropdown}
        style={{
          backgroundColor: "black",
          color: "white",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          position: "absolute",
          top: "-60px",
          left: "-30px",
        }}
      >
        {tooltip.number}
      </div>

      {/* ドロップダウンメニュー部分 */}
      {isDropdownOpen && (
        <div
          style={{
            position: "absolute",
            top: "calc(100% + -19px)",
            left: "-36px",
            backgroundColor: "white",
            border: "1px solid #ccc",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "4px",
            zIndex: 1000,
          }}
        >
          <ul style={{ listStyle: "none", margin: 0, padding: "10px" }}>
            {[...Array(tooltips.length).keys()].map((i) => (
              <li key={i + 1}>
                <button
                  onClick={() => handleSelect(i + 1)}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    padding: "5px 10px",
                    cursor: "pointer",
                    width: "100%",
                    textAlign: "left",
                  }}
                >
                  {i + 1}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default CustomTooltipDropdown;
